@import 'src/scss/core.module.scss';

$nav-header-height: 64px;

.navigation-header {
  box-sizing: border-box;
  width: 100%;
  height: $nav-header-height;
  display: flex;
  padding: 0 30px 10px 30px;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $Blue;
  position: fixed;
  top: 0;
  z-index: 2;

  .logo {
    padding-top: 10px;
  }
}

.contact p {
  font-size: medium;
  text-align: center;
}

.verify-email-page {

  .logo-page {
    text-align: center;

    img {
      width: 65%;
    }
  }

  .verify-email-form {
    .username {
      min-width: 15em;
    }

    p {
      white-space: pre-wrap;
    }

    input {
      width: 20em;
    }

    .button {
      margin-top: 0.5rem;
    }
  }

  .error-message {
    white-space: pre-wrap;
  }

  .verified-message {
    text-align: center;
  
    svg {
      color: #228B22;
      font-size: 10em;
  
    }
  }

  .success {
    text-align: center;  
  }
}

.layout {
  height: calc(100% - 64px);
  padding-top: $nav-header-height;

  .error-wrapper {
    min-height: calc(100vh - #{$nav-header-height});
  }
}

@media print {
  .navigation-header {
    display: none;
  }

  .layout {
    padding-top: 0;
  }
}