@import 'src/scss/core.module';

.status-message {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    align-items: flex-start;
    margin: 0px 0px 0px 16px;

    & > p {
        font-size: 12px;
        font-style: italic;
        margin: 0;
      }
}

