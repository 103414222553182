@import 'src/scss/core.module.scss';

.table {
  height: calc(100% - 66px);
  min-width: 670px;

  .chip {
    text-transform: capitalize;

    &.primary {
      background-color: $LightBlue;
    }
  }
}

.cc-table {
  min-width: 1400px;
}

.fabLink {
  position: absolute;
  bottom: 40px;
  right: 60px;
  z-index: 57;
  text-decoration: none;
}

.fab {
  color: $White;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.email-status {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > button {
    margin-left: 1rem;
  }

  & > div {
    margin: 8px 0;
    padding: 0 0.2rem;
  }

  & > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 40%;
  }
}
