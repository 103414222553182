@import 'src/scss/core.module';

.report-container {
  padding: 30px 60px;

  .additional-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

      .date-title {
        color: $DarkCrGray;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        display: inline-block;
      }

      .date {
        font-weight: 600;
      }
  }

  .navigation {
    display: flex;
    align-items: center;
    font-weight: 700;

    .icon {
      height: 36px;
      width: 36px;
      margin-right: 8px;
      color: $Black;

      &:hover {
        background: $SoftGray;
      }
    }
  }

  .title {
    margin: 20px 0 0 0;
    color: $Black;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 8px;
    border-bottom: 1px solid $Black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .dateRangePicker{
    display: flex;
    align-items: end;
  }

  .download-button {
    margin-left: 8px;
  }

  .dateRangeValues {
    display: none;
  }
}

@media print {
  .report-container {
    .navigation {
      visibility: hidden;
    }

    .title {
      font-size: 30px;
    }

    .download-button {
      display: none;
    }

    .dateRangePicker {
      display: none;
    }

    .dateRangeValues {
      display: inline;
    }
  }  
}
