@import 'src/scss/core.module.scss';

.login-page {
  height: 100%;

  .banner {
    background: $DarkPink;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 600px) {
      padding: 0 30px;
    }

    .title {
      margin-top: 0;
      margin-bottom: 64px;
      font-size: 57px;
      font-weight: normal;
      color: $White;
      max-width: 500px;

      @media (max-width: 600px) {
        font-size: 40px;
      }
    }

    .subtitle {
      color: $White;
      margin-top: 0;
    }

    .apps-subtitle {
      color: $White;
      font-size: 20px;
    }

    .app-logo {
      width: auto;
      height: 40px;

      & > img {
        height: 100%;
      }
    }

    .qr-code {
      width: 75px;
      height: auto;

      // Hide QR Code on mobile-ish resolutions
      @media (max-width: 600px) {
        display: none;
      }

      @media (max-width: 1000px) and (orientation: landscape) {
        display: none;
      }
    }
  }

  .content {
    padding: 48px 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .login-title {
      font-size: 27px;
      font-weight: bold;
      color: $Black;
      max-width: 450px;
      margin-bottom: 30px;
    }

    .logo {
      max-width: 150px;
    }

    .info {
      max-width: 510px;
    }
  }
}


